<template>
  <v-col cols="12">
    <p>Voicemail</p>
    <v-card elevation="0" outlined>
      <v-card-text>
        <h4 class="dark--text font-weight-medium fz-16">Voicemail greeting</h4>
      </v-card-text>
      <v-divider class="mx-4"></v-divider>
      <template v-if="defaultGreeting">
        <v-subheader class="primary--text font-weight-medium">Active greeting</v-subheader>
        <v-layout align-center justify-space-between class="px-4">
          <div>
            <p class="body-1 mb-0">{{defaultGreeting.name}}</p>
            <p class="mb-0 caption">{{defaultGreeting.duration}}s</p>
          </div>
          <v-btn
            icon
            class="text-none d-flex align-center px-0"
            @click="play(defaultGreeting.greeting_url)"
          >
            <v-icon size="30" color="#5D6AC1">{{ record === defaultGreeting.greeting_url ? 'mdi-pause' : 'mdi-play' }}</v-icon>
          </v-btn>
        </v-layout>
      </template>
      <v-card-actions class="pa-4 d-block sm-d-flex">
        <v-btn
          v-if="microphoneIsAvailable"
          color="primary"
          elevation="0"
          :outlined="$vuetify.breakpoint.smAndUp"
          :block="$vuetify.breakpoint.xs"
          @click="greetingModal = true"
        >
          <v-icon left :color="$vuetify.breakpoint.xs ? 'white' : 'primary'"
            >mdi-plus
          </v-icon>
          Record greeting
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="ms-0 mx-sm-3 pa-0 pa-sm-4 mt-3 mt-sm-0"
          :outlined="$vuetify.breakpoint.smAndUp"
          :block="$vuetify.breakpoint.xs"
          @click="greetingModalUpload = true"
        >
          <v-icon left :color="$vuetify.breakpoint.xs ? 'white' : 'primary'"
          >mdi-upload
          </v-icon>
          Upload greeting
        </v-btn>
        <v-btn
          :disabled="!voicemailsGreeting.length"
          class="people-manage-all-greetings"
          color="primary"
          elevation="0"
          :outlined="$vuetify.breakpoint.smAndUp"
          :block="$vuetify.breakpoint.xs"
          @click="allGreetingsModal = true"
        >
          Manage all greetings
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Get voicemail via email
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{ profile.vm_to_email ? 'Yes' : 'No' }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            @change="(val) => profileUpdate('vm_to_email', val, 'Get voicemail via email')"
            v-model="profile.vm_to_email"
            :input-value="profile.vm_to_email"
          ></v-switch>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Let Freefone analyze voicemail transcripts
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >Improve voicemail transcription quality
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch></v-switch>
        </v-list-item>
      </v-list> -->
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Voicemail transcripts
            </v-list-item-title>
            <v-list-item-subtitle class="caption"
              >Applies to new messages only
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-switch
            @change="(val) => changeTranscribe(val)"
            v-model="profile.vm_transcrib"
            :input-value="profile.vm_transcrib"
          ></v-switch>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium"
              >Call to listen
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              Check and manage your voicemails by calling your FreeFone Voice
              number
<!--               <v-btn
                class="caption pa-0 text-capitalize"
                :ripple="false"
                plain
                text
                color="info"
                >Learn more
              </v-btn> -->
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-switch
            v-model="profile.call_to_listen_vm"
            :input-value="profile.call_to_listen_vm"
            @change="(val) => profileUpdate('call_to_listen_vm', val, 'Call to listen')"
          ></v-switch>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
    </v-card>

    <v-dialog v-model="greetingModalUpload" width="360">
      <v-card>
        <v-card-title class="body-1 font-weight-medium">
          Upload new greeting
          <v-spacer></v-spacer>
          <v-btn @click="closeRecordModal" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex align-center px-6 py-2">
          <span class="mr-3">Name</span>
          <v-text-field
            v-model="recordingName"
            flat
            hide-details
            solo
            outlined
            dense
            height="auto"
          ></v-text-field>
        </div>

        <div class="my-3 d-flex justify-center align-center">
          <div class="my-3 pa-7 d-flex flex-column justify-center align-center cursor-p w-100"
               @click="$refs.fileInput.click()"
               @dragover="dragover"
               @dragleave="dragleave"
               @drop="drop"
               style="margin: 10px"
               :style="{border: `3px solid ${uploadFile || dragAndDrop ? '#50af56' : '#5758b0'}20`}">
            <v-btn
              :style="{ background: uploadFile || dragAndDrop ? '#50af56' : '#5758b0' }"
              class="circle"
              width="90px"
              height="90px"
              icon
            >
              <v-icon size="60px" color="#fff">mdi-upload</v-icon>
            </v-btn>
            <div class="mt-2" v-if="!uploadFile">Drop file here to upload</div>
            <input ref="fileInput" style="display: none" :name="fileName" type="file" accept=".mp3"
                   @change="onFileChange" />
            <span class="" v-if="uploadFile">{{uploadFile.name}}</span>
            <p v-if="errorUpload" class="text-red-700">{{ errorUpload }}</p>
          </div>
        </div>

        <div class="pb-6 px-6">
          <div class="justify-center d-flex">
            <v-btn
              :loading="loadingUploadFile"
              :disabled="recordingName === '' || !uploadFile"
              height="40px"
              class="rounded-square primary mt-2"
              @click="saveWithFile"
            >
              <span>Save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="greetingModal" width="360">
      <v-card>
        <v-card-title class="body-1 font-weight-medium">
          New greeting
          <v-spacer></v-spacer>
          <v-btn @click="closeRecordModal" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="d-flex align-center px-6 py-2">
          <span class="mr-3">Name</span>
          <v-text-field
            v-model="recordingName"
            flat
            hide-details
            solo
            outlined
            dense
            height="auto"
          ></v-text-field>
        </div>
        <div @contextmenu="contextmenu">
          <div v-if="!hasRecording" class="my-3 d-flex justify-center align-center">
            <div class="my-3 pa-7 d-flex justify-center w-max circle" :style="{border: `30px solid ${isRecording ? '#50af56' : '#5758b0'}20`}">
              <v-btn
                :style="{ background: isRecording ? '#50af56' : '#5758b0' }"
                class="circle"
                width="90px"
                height="90px"
                icon
                @mousedown="isDesktop ? recordAudio() : ''"
                @mouseup="isDesktop ? stop() : ''"
                @pointerdown="!isDesktop ? recordAudio() : ''"
                @pointerup.stop.prevent="!isDesktop ? stop() : ''"
              >
                <v-icon id="recorder" size="60px" color="#fff">mdi-microphone</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="!hasRecording" class="text-center font-weight-medium text--black body-1 pb-6 px-6">
            {{ isRecording ? 'Recording...' : 'Hold microphone to record'}}
          </div>
          <div v-else-if="!isRecording" class="pb-6 px-6">
            <div class="d-flex align-center my-4">
              <v-btn
                class="text-none d-flex align-center primary"
                @click="recordedChunk = null"
              >
                Record again
              </v-btn>
            </div>
            <div class="d-flex align-center">
              <label class="body-1">Listen on the Record</label>
              <v-btn
                icon
                class="text-none d-flex align-center px-0"
                @click="play(recording)"
              >
                <v-icon size="30" color="#5D6AC1">{{ record === recording ? 'mdi-pause' : 'mdi-play' }}</v-icon>
              </v-btn>
            </div>
            <div>
              <v-btn
                :disabled="!recordingName.trim()"
                height="40px"
                class="rounded-square primary mt-2"
                @click="save"
              >
                <span>Save Record</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="allGreetingsModal" width="360">
      <v-card>
        <v-card-title class="body-1">
          All greetings
          <v-spacer></v-spacer>
          <v-btn @click="allGreetingsModal = false" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <div class="greeting-wrapper">
          <v-card-text class="py-0">
            <div v-for="(greeting, index) in voicemailsGreeting" :key="index + 'greeting'" class="my-7 d-flex justify-space-between align-center">
              <div class="d-flex flex-column">
                <span v-if="greeting.is_default" class="caption greeting_subtitle font-weight-medium">
                  Active
                </span>
                <span class="body-1 greeting_content">{{greeting.name}}</span>
                <span class="caption greeting_content_duration">{{greeting.duration}}s</span>
              </div>
              <div class="d-flex align-center">
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="text-none d-flex align-center px-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="play(greeting.greeting_url)"
                    >
                      <v-icon size="30" color="#5D6AC1">{{ record === greeting.greeting_url ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ record === greeting.greeting_url ? 'Pause' : 'Play' }}</span>
                </v-tooltip>
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="text-none d-flex align-center px-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDefault(greeting)"
                    >
                      <v-icon size="22" color="#5D6AC1">{{ greeting.is_default ? 'mdi-comment-check' : 'mdi-comment-check-outline' }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ greeting.is_default ? 'Default greeting' : 'Set as default' }}</span>
                </v-tooltip>
                <v-tooltip top content-class="v-tooltip--top">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="text-none d-flex align-center px-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="openRemoveDialog(greeting.uuid)"
                    >
                      <v-icon size="25" color="#5D6AC1">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteModal" width="360">
      <v-card>
        <v-card-title class="body-1 font-weight-medium">
          Delete greeting
          <v-spacer></v-spacer>
          <v-btn @click="closeDeleteModal" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="padding-bottom:4px">
          Do you want to delete greeting?
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            :width="isDesktop ? '90px' : ''"
            height="36px"
            elevation="0"
            outlined
            @click="closeDeleteModal"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            :width="isDesktop ? '80px' : ''"
            height="36px"
            elevation="0"
            @click="removeGreeting"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <audio
      :src="record"
      ref="audio"
      @ended="ended"
    ></audio>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'settingsVoicemail',
  props: ['profileData'],
  data: () => {
    return {
      microphoneIsAvailable: false,
      greetingModal: false,
      greetingModalUpload: false,
      dragAndDrop: false,
      fileName: '',
      errorUpload: '',
      uploadFile: null,
      loadingUploadFile: false,
      allGreetingsModal: false,
      profile: {
        vm_to_email: false,
        transcribe_vm: false,
        call_to_listen_vm: false
      },
      record: '',
      recordingName: '',
      stream: null,
      recorder: null,
      recordedChunk: null,
      recordingBlob: null,
      recording: '',
      recordingTimer: null,
      recordingDuration: 0,
      deleteModal: false,
      deleteUuid: ''
    }
  },
  computed: {
    ...mapGetters('voicemails', ['voicemailsGreeting', 'defaultGreeting']),
    isRecording () {
      return this.recorder && this.recorder.state === 'recording'
    },
    hasRecording () { return this.recordedChunk !== null },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    profileData(newVal) {
      if (newVal) {
        this.profile = this.profileData
      }
    },
    recordedChunk () {
      if (!this.recordedChunk) return
      this.recordingBlob = new Blob([this.recordedChunk], {
        type: 'audio/mpeg-3'
      })
      this.recording = window.URL.createObjectURL(this.recordingBlob)
    },
    allGreetingsModal (val) {
      if (!val) return
      this.getVoicemailsGreeting()
    },
    greetingModal (val) {
      if (val) return
      this.closeRecordModal()
    }
  },
  created () {
    this.getVoicemailsGreeting()
  },
  beforeDestroy () {
    window.removeEventListener('mouseup', () => {})
  },
  mounted () {
    this.checkMicrophoneAvailability()
    const self = this
    window.addEventListener('mouseup', (event) => {
      if (event.target.id === 'recorder') return
      if (self.isRecording) self.stop()
    })
  },
  methods: {
    ...mapActions('voicemails', ['getVoicemailsGreeting', 'saveGreeting', 'deleteGreeting', 'setDefaultGreeting']),
    ...mapActions(['editUserProfile', 'showAlert', 'switchVoicemailTranscripts']),
    onFileChange(event) {
      const files = event.target.files
      if (this.checkFormatFiles(files)) {
        this.uploadFile = files[0]
      } else {
        this.showAlert({ error: 'Only one MP3 file' })
      }
    },
    checkFormatFiles(arrOfFiles, format = ['mp3']) {
      const filteredFilesByFormatAndType = [...arrOfFiles].filter(item => {
        if (format.includes(item.name.split('.')[1])) return true
        return false
      })
      return filteredFilesByFormatAndType.length === arrOfFiles.length
    },
    dragover(event) {
      event.preventDefault()
      this.dragAndDrop = true
    },
    dragleave() {
      this.dragAndDrop = false
    },
    drop(event) {
      event.preventDefault()
      if (this.checkFormatFiles(event.dataTransfer.files) && event.dataTransfer.files.length === 1) {
        this.uploadFile = event.dataTransfer.files[0]
        this.dragleave()
      } else {
        this.dragAndDrop = false
        this.showAlert({ error: 'Only one MP3 file' })
        this.uploadFile = null
      }
    },
    contextmenu (event) {
      if (this.greetingModal && event.preventDefault !== undefined) event.preventDefault()
      if (this.greetingModal && event.stopPropagation !== undefined) event.stopPropagation()
    },
    checkMicrophoneAvailability () {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          const microphones = devices.filter(device => device.kind === 'audioinput')
          if (microphones.length === 0) {
            console.log('No microphone available on this device.')
            this.microphoneIsAvailable = false
          } else {
            console.log('Microphone(s) available.')
            this.microphoneIsAvailable = true
          }
        })
        .catch((err) => {
          console.error('Error occurred:', err)
          this.microphoneIsAvailable = false
        })
    },
    async profileUpdate(key, value, message = '') {
      try {
        await this.editUserProfile({ [key]: value })
        // this.showAlert({ message: message + (value ? ' enabled' : ' disabled') })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    async changeTranscribe(value) {
      try {
        const data = await this.switchVoicemailTranscripts(value)
        // if (!data) return
        // this.showAlert({ message: 'Voicemail transcripts ' + (value ? 'enabled' : 'disabled') })
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    play (record) {
      if (this.record === record) {
        this.$refs.audio.pause()
        this.record = ''
        return
      }
      this.record = record
      setTimeout(() => {
        this.$refs.audio.play()
      }, 100)
    },
    ended () {
      this.$refs.audio.pause()
      this.record = ''
    },
    recordAudio () {
      // this.recordingName = ''
      this.recordedChunk = null
      const device = navigator.mediaDevices.getUserMedia({ audio: true })
      device.then((stream) => {
        this.stream = stream
        this.recorder = new MediaRecorder(stream)
        this.recorder.ondataavailable = this.handleDataAvailable
        this.recorder.start()
        this.recordingDuration = 0
        this.calculateRecDuration()
      })
    },
    stop () {
      if (!this.isRecording) {
        this.recorder = null
        return
      }
      this.stream.getTracks().forEach(track => {
        track.enabled = false
        track.stop()
        track = null
      })
      this.recorder.stop()
      this.recorder = null
      clearInterval(this.recordingTimer)
      this.recordingTimer = null
    },
    calculateRecDuration () {
      if (!this.isRecording) return
      this.recordingTimer = setInterval(() => {
        this.recordingDuration++
      }, 1000)
    },
    handleDataAvailable (event) {
      if (event.data.size <= 0) return
      this.recordedChunk = event.data
    },
    async save () {
      try {
        const newName = this.recordingName + '.mp3'
        const newFile = new File([this.recordingBlob], newName, { type: this.recordingBlob.type })

        /* const flink = document.createElement('a')

        flink.href = URL.createObjectURL(newFile)
        flink.download = newName
        flink.click()
        flink.remove() */
        const formData = new FormData()
        formData.append('greeting', newFile)
        formData.append('duration', Math.floor(this.recordingDuration))
        formData.append('name', this.recordingName)
        formData.append('is_default', false)

        const data = await this.saveGreeting(formData)
        if (!data) return
        this.showAlert({ message: 'Success' })
        this.getVoicemailsGreeting()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.closeRecordModal()
      }
    },
    async getAudioDuration(file) {
      return new Promise((resolve, reject) => {
        const audio = new Audio()
        audio.src = URL.createObjectURL(file)
        audio.onloadedmetadata = () => {
          resolve(audio.duration)
          URL.revokeObjectURL(audio.src)
        }
        audio.onerror = () => {
          reject(new Error('Failed to load audio file.'))
          URL.revokeObjectURL(audio.src)
        }
      })
    },
    async saveWithFile () {
      try {
        this.loadingUploadFile = true
        const duration = await this.getAudioDuration(this.uploadFile)
        if (duration === Infinity) {
          this.showAlert({ error: 'Failed to get duration, please check the file' })
          return
        }
        const formData = new FormData()
        formData.append('greeting', this.uploadFile)
        formData.append('duration', Math.floor(duration))
        formData.append('name', this.uploadFile.name)
        formData.append('is_default', false)
        const data = await this.saveGreeting(formData)
        this.closeRecordModal()
        this.showAlert({ message: 'Success' })
        this.getVoicemailsGreeting()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      } finally {
        this.loadingUploadFile = false
      }
    },
    closeRecordModal () {
      this.greetingModal = false
      this.record = ''
      this.recordingName = ''
      this.stream = null
      this.recorder = null
      this.recordedChunk = null
      this.recordingBlob = null
      this.recording = ''
      this.recordingTimer = null
      this.recordingDuration = 0
      this.uploadFile = null
      this.greetingModalUpload = false
      this.dragAndDrop = false
    },
    openRemoveDialog (uuid) {
      this.deleteUuid = uuid
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
      this.deleteUuid = ''
    },
    async removeGreeting () {
      try {
        if (!this.deleteUuid) return
        const data = await this.deleteGreeting(this.deleteUuid)
        this.showAlert({ message: data.success })
        this.closeDeleteModal()
        this.getVoicemailsGreeting()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    },
    async setDefault (greeting) {
      try {
        if (greeting.is_default || !greeting.uuid) return
        const data = await this.setDefaultGreeting(greeting.uuid)
        if (!data) return
        this.showAlert({ message: 'Default greeting has set successfully' })
        this.getVoicemailsGreeting()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  text-transform: none;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.icon-wrapper {
  background-color: rgba(127, 165, 232, 0.2);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-list-item__subtitle {
  white-space: normal !important;
}

.greeting_subtitle {
  color: #5758b0;
}

.greeting_content {
  color: #333333;

  &_duration {
    color: #828282;
  }
}
.people-manage-all-greetings {
  margin-left: 0 !important;
}

.greeting-wrapper {
  max-height: 80vh;
  overflow-y: auto;
}

@media (min-width: 960px) and (max-width: 1216px) {
  .people-manage-all-greetings {
    margin-top: 12px!important;
  }
}
@media (max-width: 608px) {
  .people-manage-all-greetings {
    margin-top: 12px!important;
  }
}
</style>
